import React from "react"
import { Link,graphql } from "gatsby"

import Layout from "../../../components/layout"
import HeaderSmall from "../../../components/headerSmall"
import SEO from "../../../components/seo"
import PropTypes from "prop-types"
import ComparisonTable from "../../../components/comparisonTable"

export const query = graphql`
{            
  site {
    siteMetadata {
      title,
      year
    }
  },
  table: allTurbatforsikring2020Csv(sort: {fields: Defaqto_Stjerne, order: DESC}) {
    edges {
      node{
        Provider
        Product
        Defaqto_Stjerne
      }
    }
  }
}
`

const IndexPage = ({data,InsuranceType,Year,Entity,siteSection}) => (
  <Layout>
    <SEO title={"Sammenlign "+InsuranceType+" | Oversikt for "+data.site.siteMetadata.year}
         description={"Oversiktlig sammenligning av alle "+InsuranceType+"er som tilbys i Norge. Forsikringene kan sammenlignes etter kvalitet på vilkår og dekning."}
         schema={`
              {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Bestforsikring.no",
                "item": "https://bestforsikring.no"
              },{
                "@type": "ListItem",
                "position": 2,
                "name": "Båtforsikring",
                "item": "https://bestforsikring.no/bat"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "Sammenlign turbåtforsikringer",
                "item": "https://bestforsikring.no/bat/turbat/sammenlign"
              }]
            }
         `}
    />
    <HeaderSmall siteSection={siteSection}/>

    <div className="moto-widget moto-widget-row row-fixed moto-bg-color5_5 moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="lala" style={{}} data-bg-position="left top" data-draggable-disabled>
      <div className="container-fluid">
        <div className="row" data-container="container">
          <div className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
            <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama" data-visible-on="mobile-v" data-animation data-draggable-disabled>
              <div className="moto-widget-text-content moto-widget-text-editable"><h1 className="moto-text_system_12" style={{textAlign: 'center'}}>Sammenlign alle {InsuranceType}er i Norge</h1><p className="moto-text_system_7" style={{textAlign: 'center'}}><br /></p>
                <p className="moto-text_system_7" style={{textAlign: 'center'}}>- enkel sammenligning av kvalitet på vilkår og dekning</p><p className="moto-text_system_7" style={{textAlign: 'center'}}><br /></p>
                <p className="moto-text_normal" style={{textAlign: 'center'}}>Følgende oversikt viser en ekspervurdering og sammenligning av kvaliteten på alle {InsuranceType}ene som tilbys åpent til privatpersoner i det norske markedet i {data.site.siteMetadata.year}. Forsikringene som har fått 5 Defaqto-stjerner er de beste.</p>

                <p className="moto-text_system_7" style={{textAlign: 'center'}}><br /></p>
                <p className="moto-text_system_7" style={{textAlign: 'center'}}><br /></p>

                <div className="moto-text_normal" style={{marginLeft: 'auto',marginRight: 'auto'}}>
                  <ComparisonTable objList={data.table} Year={Year} siteSection={siteSection}/>
                </div>

                <p className="moto-text_system_7" style={{textAlign: 'center'}}><br /></p>
                {/*<p className="moto-text_normal" style={{textAlign: 'center'}}>
                  <sup>*</sup>Forsikringer av typen Delkasko og Ansvar er ikke vurdert. Disse forsikringene er i stor grad standardiserte på tvers av selskapene, det vil si at dekningene er relativt like med kun mindre forskjeller fra selskap til selskap.
                </p>*/}

                <p className="moto-text_system_7" style={{textAlign: 'center'}}><br /></p>
                <p className="moto-text_system_7" style={{textAlign: 'center'}}><br /></p>
                <p className="moto-text_system_7" style={{textAlign: 'center'}}>Lurer du på hvor mange Defaqto stjerner som er godt nok for ditt forsikringsbehov? Ta en titt på <Link to={"/"+siteSection+"/"+Year+"/guide"}>forsikringsguiden vår for {Entity}</Link>.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

IndexPage.propTypes = {
  InsuranceType: PropTypes.string,
  Year: PropTypes.string,
  Entity: PropTypes.string,
  siteSection: PropTypes.string
}

IndexPage.defaultProps = {
  InsuranceType: 'turbåtforsikring',
  Year: '2021',
  Entity: 'båt',
  siteSection: "bat/turbat"
}

export default IndexPage
